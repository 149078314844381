/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../app/services/graphql/types.generated';

import { api } from '@/app/services/graphql/api';
export type GetMainFolderIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMainFolderIdQuery = { __typename?: 'Query', folder?: Array<{ __typename?: 'FolderOutputType', id: string, name: string }> };

export type GetFolderListQueryVariables = Types.Exact<{
  parent_folder_id: Array<Types.InputMaybe<Types.Scalars['Int']['input']>> | Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetFolderListQuery = { __typename?: 'Query', folder?: Array<{ __typename?: 'FolderOutputType', name: string, parent_folder_id?: number, id: string, created_at: any, updated_at: any, file: Array<{ __typename?: 'FileOutputType', id: string, size: number }> }> };

export type GetFolderTitleQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetFolderTitleQuery = { __typename?: 'Query', folder?: Array<{ __typename?: 'FolderOutputType', id: string, name: string, parent_folder_id?: number }> };

export type GetFileListQueryVariables = Types.Exact<{
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  folder_id?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']['input']>> | Types.InputMaybe<Types.Scalars['Int']['input']>>;
}>;


export type GetFileListQuery = { __typename?: 'Query', file?: Array<{ __typename?: 'FileOutputType', name: string, file_type: string, content_type: string, size: number, folder_id?: number, id: string, created_at: any, folder?: { __typename?: 'FolderOutputType', id: string, name: string } }> };

export type GetStorageSpaceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStorageSpaceQuery = { __typename?: 'Query', snapshot?: Array<{ __typename?: 'SnapshotOutputType', file_type: string, size: number, id: string, created_at: any, updated_at: any, percent_usage?: number }>, organisations?: Array<{ __typename?: 'OrganisationOutputType', size_threshold_per_organisation?: number }> };

export type FolderAndFileByIdQueryVariables = Types.Exact<{
  parent_folder_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type FolderAndFileByIdQuery = { __typename?: 'Query', folder?: Array<{ __typename?: 'FolderOutputType', name: string, game_id?: number, organisation_id: number, created_by?: number, parent_folder_id?: number, id: string, created_at: any, updated_at: any, file: Array<{ __typename?: 'FileOutputType', id: string, size: number }> }>, file?: Array<{ __typename?: 'FileOutputType', name: string, file_type: string, content_type: string, size: number, organisation_id: number, created_by: number, folder_id?: number, id: string, created_at: any, updated_at: any }> };

export type GetAdminRootFolderListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAdminRootFolderListQuery = { __typename?: 'Query', folder?: Array<{ __typename?: 'FolderOutputType', name: string, game_id?: number, organisation_id: number, created_by?: number, parent_folder_id?: number, id: string, created_at: any, updated_at: any, organisation?: { __typename?: 'OrganisationOutputType', id: string, size_threshold_per_organisation?: number } }> };

export type SearchFolderAndFileQueryVariables = Types.Exact<{
  search_name?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchFolderAndFileQuery = { __typename?: 'Query', folder?: Array<{ __typename?: 'FolderOutputType', name: string, game_id?: number, organisation_id: number, created_by?: number, parent_folder_id?: number, id: string, created_at: any, updated_at: any }>, file?: Array<{ __typename?: 'FileOutputType', name: string, file_type: string, content_type: string, size: number, organisation_id: number, created_by: number, folder_id?: number, id: string, created_at: any, updated_at: any }> };


export const GetMainFolderIdDocument = `
    query GetMainFolderId {
  folder(filters: {parent_folder_id: null}) {
    id
    name
  }
}
    `;
export const GetFolderListDocument = `
    query GetFolderList($parent_folder_id: [Int]!) {
  folder(
    filters: {parent_folder_id: $parent_folder_id}
    sort_by: "updated_at"
    sort_dir: "desc"
    page_size: 3
  ) {
    name
    parent_folder_id
    id
    created_at
    updated_at
    file {
      id
      size
    }
  }
}
    `;
export const GetFolderTitleDocument = `
    query GetFolderTitle($id: Int!) {
  folder(filters: {id: [$id]}) {
    id
    name
    parent_folder_id
  }
}
    `;
export const GetFileListDocument = `
    query GetFileList($page_size: Int, $folder_id: [Int]) {
  file(
    filters: {folder_id: $folder_id}
    sort_by: "created_at"
    sort_dir: "desc"
    page_size: $page_size
  ) {
    name
    file_type
    content_type
    size
    folder_id
    id
    created_at
    folder {
      id
      name
    }
  }
}
    `;
export const GetStorageSpaceDocument = `
    query GetStorageSpace {
  snapshot {
    file_type
    size
    id
    created_at
    updated_at
    percent_usage
  }
  organisations {
    size_threshold_per_organisation
  }
}
    `;
export const FolderAndFileByIdDocument = `
    query FolderAndFileById($parent_folder_id: Int) {
  folder(
    filters: {parent_folder_id: [$parent_folder_id]}
    sort_by: "updated_at"
    sort_dir: "desc"
  ) {
    name
    game_id
    organisation_id
    created_by
    parent_folder_id
    id
    created_at
    updated_at
    file {
      id
      size
    }
  }
  file(filters: {folder_id: [$parent_folder_id]}) {
    name
    file_type
    content_type
    size
    organisation_id
    created_by
    folder_id
    id
    created_at
    updated_at
  }
}
    `;
export const GetAdminRootFolderListDocument = `
    query GetAdminRootFolderList {
  folder(
    filters: {parent_folder_id: null}
    sort_by: "updated_at"
    sort_dir: "desc"
  ) {
    name
    game_id
    organisation_id
    created_by
    parent_folder_id
    id
    created_at
    updated_at
    organisation {
      id
      size_threshold_per_organisation
    }
  }
}
    `;
export const SearchFolderAndFileDocument = `
    query SearchFolderAndFile($search_name: String) {
  folder(
    filters: {search_name: $search_name}
    sort_by: "updated_at"
    sort_dir: "desc"
  ) {
    name
    game_id
    organisation_id
    created_by
    parent_folder_id
    id
    created_at
    updated_at
  }
  file(filters: {search_name: $search_name}) {
    name
    file_type
    content_type
    size
    organisation_id
    created_by
    folder_id
    id
    created_at
    updated_at
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetMainFolderId: build.query<GetMainFolderIdQuery, GetMainFolderIdQueryVariables | void>({
      query: (variables) => ({ document: GetMainFolderIdDocument, variables })
    }),
    GetFolderList: build.query<GetFolderListQuery, GetFolderListQueryVariables>({
      query: (variables) => ({ document: GetFolderListDocument, variables })
    }),
    GetFolderTitle: build.query<GetFolderTitleQuery, GetFolderTitleQueryVariables>({
      query: (variables) => ({ document: GetFolderTitleDocument, variables })
    }),
    GetFileList: build.query<GetFileListQuery, GetFileListQueryVariables | void>({
      query: (variables) => ({ document: GetFileListDocument, variables })
    }),
    GetStorageSpace: build.query<GetStorageSpaceQuery, GetStorageSpaceQueryVariables | void>({
      query: (variables) => ({ document: GetStorageSpaceDocument, variables })
    }),
    FolderAndFileById: build.query<FolderAndFileByIdQuery, FolderAndFileByIdQueryVariables | void>({
      query: (variables) => ({ document: FolderAndFileByIdDocument, variables })
    }),
    GetAdminRootFolderList: build.query<GetAdminRootFolderListQuery, GetAdminRootFolderListQueryVariables | void>({
      query: (variables) => ({ document: GetAdminRootFolderListDocument, variables })
    }),
    SearchFolderAndFile: build.query<SearchFolderAndFileQuery, SearchFolderAndFileQueryVariables | void>({
      query: (variables) => ({ document: SearchFolderAndFileDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetMainFolderIdQuery, useLazyGetMainFolderIdQuery, useGetFolderListQuery, useLazyGetFolderListQuery, useGetFolderTitleQuery, useLazyGetFolderTitleQuery, useGetFileListQuery, useLazyGetFileListQuery, useGetStorageSpaceQuery, useLazyGetStorageSpaceQuery, useFolderAndFileByIdQuery, useLazyFolderAndFileByIdQuery, useGetAdminRootFolderListQuery, useLazyGetAdminRootFolderListQuery, useSearchFolderAndFileQuery, useLazySearchFolderAndFileQuery } = injectedRtkApi;

